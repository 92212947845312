import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getDataUniversalViewer, getDataUniversal } from '../../api/rest';
import { AccessDenied } from '../../../../ui/src/components/errorBoundary/AccessDenied';
import { CONFERENCE_APP_TITLE } from '../storage/components/Conference/ConferenceTitle';

const useStyles = makeStyles((theme) => ({
  iframeContainer: {
    width: '100%',
    height: '100vh', // You can adjust the height as needed
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
  black: {
    backgroundColor: 'black',
  }
}));

export const generateUniviewerUrl = (data, appTitle=null) => {
  // Study data is passed (data.props from Aggrid)
  // console.log('generateUniviewerUrl', data);
  const univUrlBase = window.location.origin + '/universal-viewer';
  let univUrlparams = '?orgId=' + data.org
    + '&studyUId=' + data.uuid;
    // Add the page title, if exists
    if (appTitle) {
      if (data.name) {
        univUrlparams += '&title=' +data.name+ ' | ' + CONFERENCE_APP_TITLE;
      } else {
        // Don't include study name
        univUrlparams += '&title=' + CONFERENCE_APP_TITLE;
      }
    }
  return univUrlBase + univUrlparams;
}

export const openUniviewer = (data) => {
  const appTitle = true;
  const univiewerUrl = generateUniviewerUrl(data, appTitle);
  console.log('openUniviewer', univiewerUrl);
  window.open(univiewerUrl);
}

function UniversalViewerContainer() {

  /**
   * This component opens Universal Viewer in an iframe
   * The container frame (the current component) can also display details
   * about what is being shown in the Viewer.
   *
   * Expect that this component is loaded by a URL with parameters
   * to specify what data should be displayed in the viewer.
   */
  const classes = useStyles();

  /**
   * Data for Universal Viewer. This is a JSON object containing all of the
   * information needed to open Universal Viewer with any list of studies.
   * It also includes data that is displayed in the viewer, such as patient info.
   * */
  const [dataReady, setDataReady] = useState(false);
  const [uniViewerData, setUniViewerData] = useState({});

  // State for the URL used to load Universal Viewer in the iframe
  const [iFrameUrlUniViewer, setiFrameUrlUniViewer] = useState('');
  // Flag is set true if user does not have access rights on this study
  const [accessDenied, setAccessDenied] = useState(false);

  // useEffect triggered when uniViewerData has been set
  // Only when this data is ready, set the flag to display iframe with viewer
  useEffect(() => {
    // Check if uniViewerData is populated
    if (!uniViewerData.hasOwnProperty('universalGetPatient')) {
      console.debug('uniViewerData NOT READY, ignore');
      return;
    } else {
      // Check if patient data is populated
      if (uniViewerData.universalGetPatient == null) {
        console.debug('universalGetPatient NOT READY, ignore');
        return;
      }
    }

    /**  Set the **Proxy** URL for universal viewer.
     * This URL is used inside the iframe, for universal viewer.
     * This is NOT the URL shown in the browser toolbar.
    */
    // Bugfix: Default study that will be selected and displayed in Universal Viewer
    const firstStudy = uniViewerData.selectedStudy;
    const pid = uniViewerData.universalGetPatient.patientID;
    const url = `/univiewer/viewer?uniqueStudyId=${firstStudy.uniqueId}&institutionCode=${firstStudy.institutionCode}&patientId=${pid}`;

    console.debug('UNIVIEWER Proxy URL', url);
    setiFrameUrlUniViewer(url);
    setDataReady(true);

    // Add a message event listener to receive messages from the iframe
    const handleMessage = (event) => {
      const message = event.data;
      if (message.source && message.source.startsWith('react-devtools')) {
        // Internal message from React JS, ignore
        console.debug('Internal message from React JS, ignore');
        return;
      }
      if (message.type === "webpackWarnings") {
        console.log('Ignore webpackWarnings');
        return;
      }

      // Handle the incoming messages from Universal Viewer
      if (message.name === "universalGetStudyList") {
        message.response = uniViewerData.universalGetStudyList;
        console.log('Reply with STUDY LIST', message.response);
        event.source.postMessage(message, event.origin);
      } else if (message.name === "universalGetPatient") {
        message.response = uniViewerData.universalGetPatient;
        console.log('Reply with PATIENT info', message.response);
        event.source.postMessage(message, event.origin);
      } else if (message.name === "universalGetSeriesList") {

        /**
         * Viewer will send one of these requests for EACH Study.
         * If the requested filter matches multiple studies, this will be called multiple times.
         * Each request message includes:
         * uniqueStudyId of the Study.
         *
         * The response to this message is a list of Series (with thumbnail URL, etc)
         * for each study.
         * */
        const requestedStudy = message.request.uniqueStudyId;
        // Get data for this study from uniViewerData.seriesData
        // The key for series data is: [requestedStudy]
        const seriesForStudy = uniViewerData.seriesData[requestedStudy];
        message.response = seriesForStudy;
        console.log('Reply with SERIES info', message.response);
        event.source.postMessage(message, event.origin);
      } else if (message.name === "universalGetInstanceList") {

        /**
         * Viewer will send one of these requests for EACH Series in a Study.
         * e.g. a Study has 3 series, there will be 3 of these requests.
         * Each request message includes:
         * uniqueStudyId and uniqueSeriesId of the requested series.
         * The response should have ALL instances for the given series.
         * */
        const requestedStudy = message.request.uniqueStudyId;
        const requestedSeries = message.request.uniqueSeriesId;
        // Send all instances for the Series here.
        // The key for instance data is: [requestedStudy][requestedSeries]
        const instancesForSeries = uniViewerData.instanceData[requestedStudy][requestedSeries];
        message.response = instancesForSeries;
        console.log('Reply with', instancesForSeries.length,
          'INSTANCES in Study:', requestedStudy,
          'Series:', requestedSeries
        );
        event.source.postMessage(message, event.origin);
      } else {
        // Other message
        console.log('TODO: handle message', message);
      };
    };

    window.addEventListener('message', handleMessage);

    // Clean up the event listener
    return () => {
      window.removeEventListener('message', handleMessage);
    };

  }, [uniViewerData]);

  // This useEffect fires when component has loaded
  useEffect(() => {
    /**
     * Get URL parameters, which will be used to fetch data from backend.
     * This URL is shown in the browser. It is parsed and sent to backend
     * to get all data for this set of studies.
     */

    // Decode the HTML entity before passing it to URLSearchParams
    // URL may contain HTML entities such as: &amp;
    const rawSearchParams = window.location.search;
    const parser = new DOMParser();
    const doc = parser.parseFromString(rawSearchParams, 'text/html');
    const searchParams = doc.documentElement.textContent;

    // Set page title from title param.
    // const searchParams = window.location.search;
    console.debug('searchParams', searchParams);
    const params = new URLSearchParams(window.location.search);
    const title = params.get("title");
    console.log('PAGE TITLE parameter', title);

    // Set hardcoded title for (conference) and S9 prod and staging
    if (
      window.location.hostname == 's9-mercury.mnes.life'
      || window.location.hostname == 'conference.lookrec.com'
      || window.location.hostname == 's9-prototype.lookrec-staging.work'
      || window.location.hostname == 'conference.lookrec-staging.work'
      || window.location.hostname == 'conference.localhost'
    ) {
      // if available, prepend with the title parameter
      // Replace with title parameter if available
      if (title && title.length > 0) {
        console.log('PAGE TITLE SET TO:', title);
        document.title = title;
      }
      // document.title = CONFERENCE_APP_TITLE;
      // if (title) {
      //   console.log('title parameter', title);
      //   if (title.length > 0) {
      //     document.title = title + ' | ' + CONFERENCE_APP_TITLE;
      //   }
      // }
    }

    // Pass the search params to getDataUniversal API
    const fetchData = async () => {
      console.log('Waiting for getDataUniversal...');
      const data = await getDataUniversal(searchParams);
      if (data){
        console.log('UniversalViewer data', data);
        // Handle if the user does not have access
        if (data.access_denied) {
          console.warn('ACCESS DENIED on', searchParams);
          setAccessDenied(true);
        } else {
          // We have all of the required data for showing Universal Viewer.
          // Set state to trigger rendering of viewer
          setUniViewerData(data);
        }
      } else {
        // The request did not return any data
        console.warn('No data for search params', searchParams);
      }
    }
    fetchData();

    // Clean up the event listener
    return () => {
      console.log('UniversalViewerContainer UNMOUNTED?');
      // window.removeEventListener('message', handleMessage);
    };
  }, []);


  return (
    // Render the viewer container if access is allowed
    <div>
      { accessDenied ? (
          <AccessDenied />
        ) : (
          <div className={classes.black}>
          {/* <Typography>Universal Viewer Frame</Typography> */}
          <div className={classes.iframeContainer}>
            {/* Render the Universal Viewer iframe only when data from backend is ready */}
            { dataReady && (
              <iframe
                id="univiewer-frame"
                title="External Page"
                // Lookrec Universal Viewer
                // src="/viewer/viewer"
                src={iFrameUrlUniViewer}
                className={classes.iframe}
              ></iframe>
            )}
          </div>
        </div>
      )}
    </div>

  );
}

export default UniversalViewerContainer;
